/* eslint-disable */
const icon = require('vue-svgicon')
icon.register({
  'world': {
    width: 16,
    height: 16,
    viewBox: '-1 -1 213.0 213.0',
    data: '<g fill="#fff">' +
      '<circle class="cls-2" cx="103.98" cy="103.98" r="102.98" stroke="#757575" fill="fff"  stroke-miterlimit="10" stroke-width="6px"/>' +
      '<path class="cls-1" fill="#757575" stroke-width="0px" d="M103.98,1c-2.53,0-17.27.26-30.69,4.65-5.67,1.86-15.62,5.67-22.33,10.13-7.13,4.74-13.48,9.12-18.21,13.92-4.69,4.76-12.04,12.77-15.57,18.51s-3.15,5.46-6.81,12.74c-3.18,6.33-6.33,15.91-6.61,19.52-.22,2.88-.64,8.2,2.31,13.2,2.34,3.97,4.44,3.74,6.6,7.92,1.97,3.82,1.05,5.63,2.31,11.88,1.03,5.12,1.67,3.99,4.29,12.54,2.9,9.47,2.6,12.41,5.94,15.84.92.94,1.99,1.79,3.96,3.96,2.7,2.97,4.06,4.49,4.62,6.6.7,2.65-.58,3.06-.33,6.6.04.56.39,4.86,3.3,8.58,2.73,3.5,4.53,2.4,6.93,5.61,2.34,3.13.68,4.24,3.3,9.24,2,3.83,5.28,7.59,5.28,7.59h0s1.69-3.06,2.31-4.95c1.44-4.42-1.78-6.07-.99-11.55.51-3.56,3.95-5.52,4.29-5.94,1.79-2.24,4.52-3,7.26-4.62,1.8-1.06,3.83-2.05,6.93-6.44,3.62-5.12,3.84-12.23,3.96-14.03.23-3.62.46-7.06-1.65-9.9-2.26-3.05-4.69-2.04-12.21-6.6-1.98-1.2-3.83-2.33-5.94-4.29-3.42-3.16-3.13-4.41-5.94-7.26-2.02-2.05-4.87-3.57-10.56-6.6-3.66-1.95-5.61-2.99-8.58-4.29-5.84-2.56-7.5-2.57-8.91-4.62-1.65-2.41-1.33-5.26-.99-8.25.6-5.37,2.55-8.92,3.96-10.23,3.89-3.59,6.83-3.37,11.72-9.9,3.68-4.91,3.07-7.7,6.6-9.57,2.47-1.31,2.61-.29,7.59-1.82,4.08-1.25,7.41-3.83,9.41-6.11,2.47-2.81,2.76-6.3,2.97-8.91.28-3.43-.31-6.19-.99-9.24-1.36-6.11-2.74-7.03-1.98-9.24,1.07-3.12,5.18-5.27,8.91-4.95.22.02,4.83.48,6.27,3.63.89,1.95-.35,2.93-.33,6.93.01,2.04.04,6.29,2.31,7.59,1.1.63,2.29.33,6.27-.99,3.67-1.21,5.5-1.82,8.58-2.97,6.82-2.56,6.92-1.48,12.21-3.63,4.74-1.93,7.88-3.26,9.9-6.6.39-.65,2.31-3.81,1.65-7.59-.48-2.78-2.04-4-6.6-9.24-5.19-5.95-4.16-6.86-5.72-6.86Z"/>' +
      '<path class="cls-1" fill="#757575" stroke-width="0px" d="M205.41,93.66c1.35-4.63-1.54-22.73-11.55-40.7-5.8-10.42-13.76-19.81-18.81-19.36-3.52.31-3.3,1.96-7.76,1.98-3.35.01-5.98-1.04-11.88-4.79-2.32-1.47-3.85-2.99-6.6-3.64-2.18-.51-4.45-.08-6.11,0-3,.16-6.62,1.13-9.24,2.81-1.2.77-3.43,2.19-3.96,4.62-.46,2.1.8,2.91.33,5.28-.4,2.04-1.72,3.37-2.64,4.29-2.34,2.35-6.42,1.48-8.25,1.98-4.16,1.13-3.72,1.66-5.94,2.97-2.03,1.2-3.7,3.55-3.96,6.27-.35,3.63,1.84,7.61,4.62,8.25,3.06.71,7.59.17,10.89-5.28,1.85-3.06,2.62-3.1,5.45-4.46,3.8-1.82,4.19-.59,5.28,0,.85.46.49,2.69,3.47,3.47,6.93,1.82,7.07,3.81,7.59,3.96.02,0,.8,1.8.83,1.82.51.33-.62-.75,0,0,.53.65.75,1.06,1.69-.46,1.15-1.86-1.62-2.54-2.17-3.5,0,0-.98-3.31-.7-4.32.32-1.14,1.22,0,3.17,1.35,2.18,1.5,1.42,3.11,3.47,5.78,1.6,2.09,4.43,2.5,6.93,2.97,2.22.42,3.63,1.59,6.77,2.15,2.8.5,3.39-.72,4.13.17,1.7,2.04.95,6.55-1.65,8.25-2.5,1.63-6.05.22-6.6,0-2.21-.88-2.8-2.17-4.95-2.31-.63-.04-.92.05-3.3.66-5.47,1.41-4.82.6-5.28.5-.68-.15-1.91.43-5.61-3.8-1.18-1.34-5.62-5.28-5.62-5.28-5.38-1.67-8.48-1.47-11.21-.99-1.46.26-1.46.41-6.93,1.98-8.16,2.35-8.46,2.1-9.9,2.97-3.54,2.13-4.97,5.53-6.93,10.23-2.74,6.54-2.91,12.2-2.97,14.85-.12,5.54.85,9.56,1.65,12.87,1.11,4.58,1.7,6.91,3.63,8.91,2.71,2.81,6.29,3.47,8.91,3.96,5.65,1.05,6.94-.76,11.55.66,1.65.51,5.11,1.57,7.26,4.62,2.18,3.1.49,4.65,2.64,11.22,1.37,4.18,2.41,4.66,2.31,7.26-.11,2.87-1.45,3.49-1.65,6.93-.16,2.71.52,4.82.99,6.27,1.41,4.37,2.94,4.22,3.96,7.92,1,3.66-.19,4.88,1.32,7.26,1.44,2.27,3.77,3.12,4.29,3.3,3.95,1.34,7.74-.86,10.23-2.31,1.98-1.15,5.18-3.4,8.25-7.92,3.02-4.44,2.03-9.18,6.64-21.89,2.64-7.28,1.78-7.81,2.6-11.44,1.25-5.54,3.2-1.54,4.95-4.79,1.88-3.5,2.44-5.03,5.61-9.74,1.86-2.76,4.97-2.45,5.28-5.45.48-4.65-5.95-8.82-4.95-12.38.1-.37.81-1.65,2.97-4.46,1.26-1.63,3.8-.89,3.8-.89,2.41-4.07,1.77-6.85,2.81-7.03,1.38-.25,1.46,2.01,6.6,5.12,1.37.83,3.94,2.53,4.29,1.32Z"/>' +
      '</g>'
  }
})
