/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vestre_logo': {
    width: 35,
    height: 35,
    viewBox: '0 0 283.5 283.5',
    data: `
      <path
        stroke="none"
        fill="yellow"
        d="M279.5 4v275.5H4V4h275.5m4-4H0v283.5h283.5V0z"
      ></path>
      <path
        stroke="black"
        d="M28.8 197.5h9l12.7 29.3 12.7-29.3h9l-18.7 43h-6l-18.7-43zm78 31.1c-2.4 2.6-5.8 5.2-11.8 5.2-7.5 0-13.4-5.8-13.9-13h33.1c.1-.8.1-1.5.1-1.8 0-12.7-8.7-22.4-20.7-22.4-12.2 0-20.9 9.5-20.9 22.4 0 12.9 9.7 22.4 22.2 22.4 8.2 0 14.1-3.4 17.4-7.2l-5.5-5.6zM94 204.2c5.1 0 10.1 3.8 11.3 9.3H81.9c1.4-5.6 6.5-9.3 12.1-9.3zm42.1 10.4c-4.3-1.1-7.7-2.1-7.7-5 0-3.4 3.6-5.4 7.8-5.4 3 0 5.8 1 8.2 2.6l5.5-5.5c-3.4-2.7-7.9-4.7-14.2-4.7-7.7 0-15.9 4.2-15.9 13.4 0 6 4.7 9.3 9.9 11.1 6.3 2.1 13.4 2.4 13.4 7.3 0 3.4-3.8 5.4-7.5 5.4-4 0-8-1.4-12.7-4.9l-5.6 5.6c5.9 4.8 11.6 6.9 18.5 6.9 8.4 0 16-5 16-13.2 0-8.9-8.9-11.9-15.7-13.6zm85.3 6.2c.5 7.2 6.4 13 13.9 13 6.4 0 9.9-3.1 12.3-5.9l5.7 5.4c-3.2 4.1-9.3 8-18.1 8-12.5 0-22.2-9.5-22.2-22.4 0-12.9 8.8-22.4 20.9-22.4 12 0 20.7 9.7 20.7 22.4 0 .4 0 1-.1 1.8h-33.1zm.7-7.3h23.4c-1.3-5.5-6.2-9.3-11.3-9.3-5.6 0-10.6 3.7-12.1 9.3zm-42.5 19.1c-1.3.4-3.5.5-4.8.5-2.3 0-4.8-.8-5.9-2.3-1.1-1.3-1.3-3.2-1.3-5.5v-20.1h12v-7.8h-12v-11.9h-8.7v39.3c0 4.7.9 8.6 3.5 11.4 2.7 3.1 6.8 5 12.2 5 1.7 0 3.5-.2 5-.5v-8.1zm32.2-35.9c-.8-.1-1.7-.1-2.4-.1-6.3 0-10.8 2.9-12.9 5.9v-4.9h-8.4v43h8.7V212c2.4-4.1 7.1-7.2 11.6-7.2 1.4 0 2.4.1 3.4.4v-8.5z"
      ></path>
    `
  }
})
